import { db } from '@/config/firebase'
import dbFilter from '@/helpers/firestoreQueryFilter'

export default {
  async getAdmins({ commit, rootState }) {
    const companyId = rootState.User.profile.company_id

    let userRef = db
      .collection('companies')
      .doc(companyId)
      .collection('users')
      .orderBy('name')
      .where('roles', 'array-contains', 'admin')

    let admins = await dbFilter.query(userRef)
    commit('changeAdmins', admins)
    return admins
  },
  async getAllUsers({ commit, rootState }) {
    const companyId = rootState.User.profile.company_id

    let userRef = db
      .collection('companies')
      .doc(companyId)
      .collection('users')
      .orderBy('name')

    let allusers = await dbFilter.query(userRef)
    commit('changeAllUsers', allusers)
    return allusers
  },
}
