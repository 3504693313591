<template>
  <div class="user-settings">
    <global-title-with-filter-bar
      :title="$route.meta.title"
      :hidedate="true"
      :buttons="buttons"
    />

    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import globalTitleWithFilterBar from '@/components/Menu/globalTitleWithFilterBar'

export default {
  name: 'DriversList',
  components: {
    globalTitleWithFilterBar
  },
  computed: {
    buttons() {
      const that = this
      return [
        {
          text: 'Motoristas',
          click: () => that.$router.push({ name: 'DriversList' }),
          type: 'primary',
          icon:"el-icon-user"
        },
        {
          text: 'Conferentes',
          click: () => that.$router.push({ name: 'CheckersList' }),
          type: 'primary',
          icon:"el-icon-finished"
        },
        {
          text: 'Ajudantes',
          click: () => that.$router.push({ name: 'DriverHelpersList' }),
          type: 'primary',
          icon:"el-icon-connection"
        },
        {
          text: 'Administradores',
          click: () => that.$router.push({ name: 'AdminsList' }),
          type: 'primary',
          icon:"el-icon-star-off"
        },
        {
          text: 'Todos',
          click: () => that.$router.push({ name: 'AllUsersList' }),
          type: 'primary',
          icon:"el-icon-user"
        },
        {
          text: 'Adicionar',
          click: () => that.$router.push({ name: 'UsersAdd' }),
          type: 'success',
          icon:"el-icon-plus"
        },
        {
          text: 'Atualizar',
          click: () => that.updateDriverList(),
          type: 'success',
          icon:"el-icon-refresh"
        }
      ]
    },
  },
  methods: {
    ...mapActions('Drivers', ['getDrivers']),
    ...mapActions('Admins', ['getAdmins']),
    ...mapActions('Checkers', ['getCheckers']),
    ...mapActions('DriverHelpers', ['getDriverHelpers']),
    ...mapMutations('Shared', ['setLoadingMessage']),
    async updateDriverList() {
      if (this.$route.name === 'AdminsList') {
        this.setLoadingMessage('Buscando usuários admins...')
        await this.getAdmins()
      } else if (this.$route.name === 'CheckersList') {
        this.setLoadingMessage('Buscando usuários conferentes...')
        await this.getCheckers()
      } else if (this.$route.name === 'DriverHelpersList') {
        this.setLoadingMessage('Buscando usuários ajudantes...')
        await this.getDriverHelpers()
      } else {
        this.setLoadingMessage('Buscando motoristas...')
        await this.getDrivers()
      }
      this.setLoadingMessage('')
    },
  },
}
</script>

<style scoped>
.el-main > div {
    width: 100%;
}

.el-main > div .header {
      width: 100%;
      display: flex;
      position: relative;
      padding-top: 10px;
}

.el-main > div .header h1 {
  width: 100%;
  padding-top: 5px;
}

.el-main > div .header .menu {
  position: absolute;
  right: 0;
}
</style>
