<template>
  <div class="users-list users-list-all">
    <list-users :users="allUsers" type="all" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ListUsers from '@/components/Admin/Users/components/list'

export default {
  name: 'AllUsersList',
  components: {
    ListUsers,
  },
  computed: {
    ...mapState('Admins', ['allUsers'])
  },
}
</script>

<style scoped>
.drivers {
  width: 100%;
}
</style>
