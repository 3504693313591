import { getDefaultAdminsState } from './state'

export default {
  resetState(state) {
    // console.log(`Admin state reseted`)
    Object.assign(state, getDefaultAdminsState())
  },
  changeAdmins(state, payload) {
    state.allAdmins = payload
  },
  changeAllUsers(state, payload) {
    state.allUsers = payload
  },
  addAdmin(state, payload) {
    state.allAdmins.push(payload)
  },
  removeAdmin(state, payload) {
    state.allAdmins = state.allAdmins.filter(
      (admin) => admin.driver_login !== payload
    )
  },
  updateAdmin(state, payload) {
    const adminIndex = state.allAdmins.findIndex(
      (admin) => admin.driver_login === payload.driver_login
    )
    if (adminIndex >= 0) {
      Object.assign(state.allAdmins[adminIndex], payload)
    } else {
      state.allAdmins.push(payload)
    }
  },
}
