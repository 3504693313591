<template>
  <div class="list-users">
    <el-row :gutter="10">
      <el-col :span="19">
        <el-input v-model="searchTerm" size="mini" placeholder="Filtrar por nome" />
      </el-col>
      <el-col :span="4">
        <el-switch v-model="showOnlyEnabled" active-text="Somente ativos" />
      </el-col>
    </el-row>
    <br />

    <el-table :data="userTable" @cell-click="cellClicked" size="mini">
      <el-table-column width="30">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.created_at && scope.row.created_at.seconds"
            effect="dark"
            :content="scope.row.is_disabled === true ? 'Desativado' : 'Ativado'"
            placement="top-start"
          >
            <el-button
              size="mini"
              circle
              :type="scope.row.is_disabled === true ? 'danger' : 'success'"
              @click="handleEnable(scope.row)"
            ></el-button>
          </el-tooltip>

          <el-tooltip
            v-else
            effect="dark"
            content="Provisionando"
            placement="top-start"
          >
            <el-button size="mini" circle type="warning"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column width="70" label="Uid">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="scope.row.auth_uid"
            placement="top-start"
          >
            <span>{{
              scope.row.auth_uid ? scope.row.auth_uid.substr(-6) : '...'
            }}</span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        width="140"
        prop="branch_id"
        label="Filial"
        :filters="branchFilterList"
        :filter-method="filterBranchHandler"
      >
        <template slot-scope="scope">
          {{ getBranchName(scope.row.branch_id) }}
        </template>
      </el-table-column>

      <!-- <el-table-column width="100" label="Tipo">
        <template>
            <span>{{ typeNormalizado }}</span>
        </template>
      </el-table-column> -->

      <el-table-column width="300" label="Tipo">
        <template slot-scope="scope">
          {{ rolesNormalizadas(scope.row.roles) }}
        </template>
      </el-table-column>

      <el-table-column prop="name" label="Nome" />
      <el-table-column prop="title" label="Cargo" />

      <el-table-column sortable prop="created_at" label="Criado em" width="150">
        <template slot-scope="scope">
          {{
            scope.row.created_at && scope.row.created_at.seconds
              ? $moment
                  .unix(scope.row.created_at.seconds)
                  .format('DD/MM/YYYY HH:mm')
              : 'Configurando...'
          }}
        </template>
      </el-table-column>

      <el-table-column width="130">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)"
              :disabled="!scope.row.auth_uid"
            />
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              disabled
              @click="handleDelete(scope.row.id, scope.row.truck_id)"
            />
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    users: {
      type: Array,
      default: () => [],
      required: true,
    },
    type: {
      type: String,
      default: 'drivers',
    },
  },
  data() {
    return {
      searchTerm: '',
      showOnlyEnabled: true
    }
  },
  computed: {
    ...mapGetters('User', ['getCompanieBranches']),
    branchFilterList() {
      return this.getCompanieBranches.map((b) =>
        this.prepareBranchFilterListObject(b)
      )
    },
    typeNormalizado() {
      return this.type === 'admins' ? 'Admin'
      : this.type === 'drivers' ? 'Motorista'
      : this.type === 'checkers' ? 'Conferente'
      : this.type === 'driverhelpers' ? 'Ajudante'
      : this.type === 'all' ? '*'
      : ''
    },
    userTable() {
      return this.users.filter((user) => {
        if (this.showOnlyEnabled) {
            return !user.is_disabled && user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        } else {
            return user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        }
      })
    }
  },
  watch: {
    users(value) {
      value.map((user) => {
        if (!user.auth_uid) {
          // console.log(
          //   'Usuário em provisionamento identificado... agendando refresh'
          // )
          if (this.type === 'all')
            setTimeout(() => {
              this.getAllUsers()
            }, 5000)
          else if (this.type === 'admins')
            setTimeout(() => {
              this.getAdmins()
            }, 5000)
          else if (this.type === 'drivers')
            setTimeout(() => {
              this.getDrivers()
            }, 5000)
          else if (this.type === 'checkers')
            setTimeout(() => {
              this.getCheckers()
            }, 5000)
            else if (this.type === 'driverhelpers')
            setTimeout(() => {
              this.getDriverHelpers()
            }, 5000)
        }
      })
    },
  },
  created() {},
  mounted() {
    if (this.type === 'all') this.getAllUsers()
  },
  methods: {
    ...mapActions('User', ['removeUser', 'enableDisableUser']),
    ...mapActions('Drivers', ['getDrivers']),
    ...mapActions('Admins', ['getAdmins', 'getAllUsers']),
    ...mapActions('Checkers', ['getCheckers']),
    ...mapActions('DriverHelpers', ['getDriverHelpers']),
    getBranchName(id) {
      const branch = this.getCompanieBranches.find((b) => b.branch_id === id)
      if (branch) return branch.branch_name
      return ''
    },
    prepareBranchFilterListObject(b) {
      return { text: b.branch_name, value: b.branch_id }
    },
    filterBranchHandler(value, row, column) {
      return row.branch_id === value
    },
    cellClicked(row, column) {
      if (column.property) {
        this.$router.push({ name: 'UsersEdit', params: { user: row } })
      }
    },
    handleEdit(row) {
      this.$router.push({ name: 'UsersEdit', params: { user: row } })
    },
    handleEnable(row) {
      const question = `Tem certeza que deseja ${
        row.is_disabled ? 'ativar' : 'desativar'
      } o usuário ${row.name}?`

      this.$confirm(question, 'Atenção!', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning',
      })
        .then(() => this.enableDisableUser(row))
        .then(() =>
          this.$message({ type: 'success', message: 'Usuário atualizado' })
        )
        .catch(() => {})
    },
    handleDelete(id, name, login) {
      const question = `Tem certeza que deseja excluir o usuário ${name}?`

      this.$confirm(question, 'Atenção!', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning',
      })
        .then(() => this.removeUser({ id, driver_login: login }))
        .then(() =>
          this.$message({ type: 'success', message: 'Usuário excluído.' })
        )
        .catch(() => {})
    },
    rolesNormalizadas(roles) {
      console.log(roles)
      const substituicoes = {
        'admin': 'Admin',
        'driver': 'Motorista',
        'checker': 'Conferente',
        'driverhelper': 'Ajudante',
        'finance': 'Financeiro',
        'logistics': 'Logística',
      };
    
      return (roles.map(role => substituicoes[role] || role)).sort().join(', ');
    }
  },
}
</script>

<style>
.list-users table {
  width: 100%;
}

.list-users table tr {
  cursor: pointer;
  text-transform: uppercase;
}
</style>
